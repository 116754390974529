//_b-base.scss

@font-face {
  font-family: 'Montserrat semi bold';
  src: url('../../fonts/Montserrat-SemiBold.eot');
  src: url('../../fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Montserrat-SemiBold.woff2') format('woff2'),
  url('../../fonts/Montserrat-SemiBold.woff') format('woff'),
  url('../../fonts/Montserrat-SemiBold.ttf') format('truetype'),
  url('../../fonts/Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../fonts/Montserrat-Regular.eot');
  src: url('../../fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Montserrat-Regular.woff2') format('woff2'),
  url('../../fonts/Montserrat-Regular.woff') format('woff'),
  url('../../fonts/Montserrat-Regular.ttf') format('truetype'),
  url('../../fonts/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
}

body {
  font-family: $font-family;
  font-size: 17px;
  font-weight: 400;
  color: #4a5153;
  @media (max-width: 992px) {
    font-size: 13px;
  }
}

.clear {
  clear: both;
  width: 100%;
}

.overh {
  overflow: hidden;
}

.container-fluid {
  overflow: hidden;
}

.ml12 {
  font-weight: 200;
  font-size: 1.8em;
  text-transform: uppercase;
  //letter-spacing: 0.5em;
}

.ml12 .letter {
  display: inline-block;
  //line-height: 1em;
}

.p100 {
  padding-top: 100px;
}

.p140 {
  padding-top: 140px;
}

img {
  max-width: 100%;
  position: relative;
  display: block;
}

.txt-c {
  text-align: center;
}

.no-marg {
  margin-left: 0;
  margin-right: 0;
}

.no-pad {
  padding-left: 0;
  padding-right: 0;
}

.full-height {
  height: 100vh !important;
}

.bg-pink {
  background: $pink;
  color: #fff;
}

.bg-green {
  background: $green;
  color: #fff;
}

.disp-desktop {
  display: block;
}

@media (max-width: 992px) {
  .disp-desktop {
    display: none;
  }
}

.disp-mobile {
  display: none;
}

@media (max-width: 992px) {
  .disp-mobile {
    display: block;
  }
}

.disp-mobile-xs {
  display: none !important;
}

@media (max-width: 1200px) {
  .disp-mobile-xs {
    display: block !important;
  }
}

.hide-mobile-xs {
  display: inline-block !important;
}

@media (max-width: 460px) {
  .hide-mobile-xs {
    display: none !important;
  }
}

.hamburger {
  display: none;
  z-index: 9;
  position: absolute;
  top: 10px;
  left: 20px;
  .line {
    width: 35px;
    height: 3px;
    background-color: #ff5570;
    display: block;
    margin: 10px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  &:hover {
    cursor: pointer;
  }

  &.is-active {
    position: fixed;
  }
  &.is-active .line:nth-child(2) {
    opacity: 0;
  }

  &.is-active .line:nth-child(1) {
    -webkit-transform: translateY(13px) rotate(45deg);
    -ms-transform: translateY(13px) rotate(45deg);
    -o-transform: translateY(13px) rotate(45deg);
    transform: translateY(13px) rotate(45deg);
  }

  &.is-active .line:nth-child(3) {
    -webkit-transform: translateY(-13px) rotate(-45deg);
    -ms-transform: translateY(-13px) rotate(-45deg);
    -o-transform: translateY(-13px) rotate(-45deg);
    transform: translateY(-13px) rotate(-45deg);
  }

  @media (max-width: 1200px) {
    display: block;
  }
}

.menu {
  .logo {
    float: left;
    width: 30%;
    @media (max-width: 1200px) {
      text-align: center;
      margin: 30px auto 0 auto;
      padding: 0 15px;
      width: inherit;
      a {
        font-size: 36px;
      }
    }
  }
  .menu-item {
    float: left;
    width: 70%;
    li {
      width: inherit;
      margin-left: 15px;
      font-size: 16px;
      @media (max-width: 1360px) {
        margin-left: 9px;
        a {
          font-size: 15px;
        }
      }
    }
    @media (max-width: 1200px) {
      display: none;
    }
    &.menu-active {
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100%;
      margin: 0;
      z-index: 2;
      display: table;
      background-color: #40dfb2;
      ul.nav {
        height: 100vh;
        display: table-cell;
        vertical-align: middle;
        width: 100%;
        li.menu-item {
          display: inherit;
          position: relative;
          text-align: center;
          padding: 2px 5px;
          top: inherit;
          left: inherit;
          height: initial;
          @media (max-width: 1200px) {
            padding: 0px;
          }
        }
      }
    }
  }
}

.commandes2 {
  margin-top: 75px;
  display: block;
  position: relative;
  .swiper-button-next, .swiper-button-prev {
    background-size: 18px 18px;
  }
  .swiper-button-prev {
    background-image: url(../../src/img/arrow-point-to-left.png) !important;
    width: 12px;
    height: 20px;
    background-size: contain;
    outline: none;
  }
  .swiper-button-next {
    background-image: url(../../src/img/arrow-point-to-right.png) !important;
    width: 12px;
    height: 20px;
    background-size: contain;
    outline: none;
  }
  .swiper-partners {
    margin: 20px 20px 0 20px;
  }
}

footer {
  background: url("../../src/img/bg-footer.png") no-repeat center center;
  background-size: cover;
  height: 400px;
  position: relative;
  @media (max-width: 1200px) {
    height: inherit;
  }
  @media (max-width: 768px) {
    text-align: center;
    padding-bottom: 90px;
  }
  .ft-item {
    color: #ffffff;
    font-family: "Montserrat";
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;
    @media (max-width: 992px) {
      font-size: 17px;
    }
    ul {
      padding: 0;
      margin-top: 20px;
      list-style: none;
      li {
        color: #ffffff;
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        padding-bottom: 15px;
        a {
          color: #ffffff;
          font-family: Montserrat;
          font-size: 15px;
          transition: all ease 0.3s;
          &:hover {
            color: #2f8e74;
            transition: all ease 0.3s;
          }
          &.in {
            margin: auto;
            display: block;
            width: 43px;
            padding: 10px;
            border: 1px solid #fff;
            border-radius: 50px;
            text-align: center;
            transition: all ease 0.3s;
            &:hover {
              background-color: #fff;
              color: #40e0b4;
              transition: all ease 0.3s;
            }
          }
        }
      }
    }
    p {
      color: #ffffff;
      font-family: Montserrat;
      font-size: 15px;
      font-weight: 700;
      line-height: 24px;
    }
  }
}

.legals {
  width: 100%;
  background-color: #3ccaa3;
  position: absolute;
  bottom: 0;
  @media (max-width: 768px) {
    height: inherit;
  }
  p {
    opacity: 0.7;
    color: #ffffff;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    padding-top: 30px;
    padding-bottom: 10px;
    a {
      color: #ffffff;
    }
  }
}

.ft-item .ft-logo {
  color: #ffffff;
  font-size: 20px;
  font-family: Arial;
  font-weight: 400;
  img {
    margin: auto;
  }
  span {
    font-weight: bold;
    font-family: Arial;
    font-weight: 700;
  }
}

.wpml-ls-current-language {
  display: none;
}

.wpml-ls-legacy-list-vertical {
  a {
    span {
      color: #ff5570;
      font-family: "Montserrat Semi Bold";
      font-size: 10px;
      font-weight: 600;
      text-transform: uppercase;
      padding-top: 6px;
      display: block;
    }
  }
}