//_global.scss
.wrapper {
  background-color: $white;
}

section {
  padding: 40px 0;
  position: relative;
  @media (max-width: 768px) {
    padding: 20px 0;
  }
  &.white {
    background-color: $white;
  }
  &.grey {
    background-color: $grey;
  }
}

.base-curve {
  content: "";
  height: 100px;
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 1;
}

.bottom-curve-white {
  &:after {
    @extend .base-curve;
    height: 160px;
    bottom: -50px;
    background: url("../../src/img/bottom-curve-white.png") no-repeat center center;
  }
}

.bottom-curve-grey {
  &:after {
    @extend .base-curve;
    height: 160px;
    bottom: -50px;
    background: url("../../src/img/bottom-curve-grey.png") no-repeat center center;
  }
}

.curve-white {
  height: 200px;
  background: url("../../src/img/bottom-curve-white.png") no-repeat center center;
  background-size: cover;
  padding: 0px;
  display: block;
  z-index: 1;
}

.curve-grey {
  height: 200px;
  background: url("../../src/img/bottom-curve-grey.png") no-repeat center center;
  background-size: cover;
  padding: 0px;
  display: block;
  z-index: 1;
}

.cookiebanner-close{
  border: 1px solid;
  padding: 0 13px!important;
}