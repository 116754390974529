//_header.scss
.header {
  background: url(../../src/img/bld-header.svg) no-repeat bottom center;
  background-size: cover;
  padding-bottom: 110px;
  &-container {
    padding: 0 150px;
    @include media-breakpoint-between(xs, md) {
      padding: 0 20px;
    }
    @media (max-width: 1200px) {
      padding: 0 0px;
    }
  }

  .site-name {
    color: $white;
    font-size: 27px;
  }
  // PREMIERE LIGNE
  .spaces {
    padding: 10px 0 0 0px;
    .button {
      margin-right: 20px;
      margin-bottom: 15px;
      margin-top: 10px;
    }
  }

}

@media (max-width: 1200px) {
  .bloc-header {
    max-width: 100%;
    width: 100%;
    height: 100%;
  }
}


.home .header {
  background: url(../../src/img/bg-home.svg) no-repeat bottom center;
  background-size: cover;
  height: 670px;
}