.page-template-page-recrutements {
  section {
    padding: 50px 0;
    @media (max-width: 992px) {
      padding: 30px 0;
    }
  }
  article {
    .actu-item {
      box-shadow: 0 0px 15px 6px rgb(232, 232, 232);
      background-color: #ffffff;
      //border-radius: 8px;
      padding: 20px 30px;
      .actu-content {
        padding: 20px 30px 0px 30px;
        a {
          float: right;
          padding: 7px 50px;
          color: #ffffff;
          font-family: Montserrat;
          font-size: 17px;
          font-weight: 400;
        }
      }
      .actu-title {
        font-family: "Montserrat";
        color: #4a5153;
        font-size: 25px;
        font-weight: 600;
      }
      .actu-date {
        font-family: "Montserrat";
        color: #4a5153;
        font-size: 17px;
        font-weight: 500;
        margin: 0;
      }
      &:hover {
        background-color: #ffa71d;
        .actu-content {
          color: #fff;
          a {
            &:hover {
              background-color: #fff;
              color: #FF5570;
            }
          }
        }
        .actu-title {
          color: #fff;
        }
        .actu-date {
          color: #fff;
        }
      }
    }
    margin: 0px 0 80px 0;
  }
}

.postuler {
  padding: 7px 40px;
  font-family: Montserrat;
  font-size: 17px;
  font-weight: 400;
}

.low{
  text-transform: lowercase;
}

.bt-rec-left,
.bt-rec-top{
  float: left;
  color:#fff!important;
  &:hover{
    color: #ff5570!important;
  }
}

.bt-rec-top{
  float: left;
  color:#fff!important;
  margin:20px 0 50px 0;
  &:hover{
    color: #ff5570!important;
  }
}
.bt-rec-right{
  float: right;
  padding: 7px 40px;
}