//_c-language.scss
.language{
	width: 36px;
	height: 36px;
	display: inline-block;
	vertical-align: middle;
	color: $pink;
	font-size: 10px;
	line-height: 36px;
	transition: color 0.5s ease;
	position: relative;
	
	img{
		position: absolute;
		top: 0;
		left: 0;
		transition: transform 0.5s ease;
	}
	&:hover{
		color: $pink;
		img{
			transform: rotate(180deg); 
		}
	}
}