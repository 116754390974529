//_link.scss
a{
	text-decoration: none;
	&:hover{
		text-decoration: none;
	}
}
.link--green{ 
	color: $green; 
	&:hover{
		color: $green;
	}
}
.link--pink{ 
	color: $pink; 
	&:hover{
		color: $pink;
	}
}
