//_c-button.scss
.button{
	display: inline-block;
	padding: 6px 20px;
	border-radius: 20px;
	transition: all 0.5s ease;
	&--custom{
		border: 1px solid transparent;
		background-color: $pink;
		color: $white;
		&:hover{
			color: $pink;
			background-color: transparent;
			border: 1px solid $pink;
		}
	}
}