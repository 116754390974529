//_p-presentation.scss
.titre-center {
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
  color: $white;
  margin-top: 20px;
  padding-bottom: 200px;
  width: 100%;
  @media (max-width: 992px) {
    width: 100%;
    display: block;
    text-align: center;
    font-size: 28px;
  }
  .phrase {
    h1 {
      transition: all 0.5s ease;
      color: #ffffff;
      font-family: "Montserrat Semi Bold";
      font-weight: 600;
      line-height: 60px;
      text-align: center;
      text-transform: uppercase;
      font-size: 30px;
      margin-bottom: 30px;

      @media (max-width: 992px) {
        width: 100%;
        display: block;
      }
      @media (max-width: 768px) {
        line-height: 40px;
        font-size: 26px;
      }
    }
  }
  .author {
    height: 43px;
    color: #ffffff;
    text-align: right;
    display: block;
    font-weight: 400;
    text-transform: initial;
    font-family: "Montserrat";
    font-size: 25px;
    @media (max-width: 992px) {
      width: 100%;
      display: block;
      text-align: center;
      font-size: 24px;
    }
    @media (max-width: 768px) {
      margin-top: 20px;
    }
  }
}

.intro {
  text-align: center;
  p {
    color: #4a5153;
    font-family: Montserrat;
    font-size: 21px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0.11px;
  }
}

.intro_image {
  margin-bottom: 20px;
  img {
    float: left;
    margin-right: 20px;
    @media (max-width: 992px) {
      margin: 0 auto 20px auto;
      float: inherit;
    }
  }
  h2 {
    color: #ff5570;
    font-family: "Montserrat";
    font-size: 17px;
    font-weight: 600;
    line-height: 30px;
    text-transform: uppercase;
    @media (max-width: 992px) {
      text-align: center;
    }
  }
  p {
    @media (max-width: 992px) {
      text-align: center;
    }
  }
}

.presentation-elem {
  min-height: 700px;
  margin-top: -100px;
  .txt {
    padding: 40px;
    @media (max-width: 992px) {
      padding: 20px;

    }
    h2 {
      color: #ffffff;
      font-family: "Montserrat";
      font-size: 30px;
      font-weight: 600;
      line-height: 45px;
      text-transform: uppercase;
      margin-bottom: 30px;
      @media (max-width: 992px) {
        text-align: center;
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
  .bg-equipe {
    background-position: center;
    background-size: cover;
    @media (max-width: 992px) {
      background-position: top;
      height: 280px;
    }
  }
}

@media (max-width: 768px) {
  .home-curve-grey{
    display: none;
  }
}

.section_3 {
  .intro_image {
    img {
      height: 134px;
      width: 134px;
    }
  }
}

.section_5 {
  text-align: center;
  img {
    margin: 0px auto 20px auto;
    display: block;
    float: inherit;
  }
}

.section_6 {
  text-align: center;
  margin-top: -100px;
  padding-top: 180px;
  h2 {
    color: $pink;
    font-family: "Montserrat";
    font-size: 40px;
    font-weight: 600;
    line-height: 55px;
    margin-bottom: 50px;
    text-transform: uppercase;
  }
  img {
    margin: auto;
  }
}

.pieuvre {
  padding-top: 100px;
  @media (max-width: 992px) {
    padding-top: 20px;
  }
  .mrg1 {
    margin-top: -100px;
    @media (max-width: 992px) {
      margin-top: 0px;
    }
    &.z-1 {
      z-index: -1;
    }
  }
  .branche {
    display: block;
    width: 286px;
    height: 286px;
    margin: auto;
    transition: all ease 0.4s;
    @media (max-width: 992px) {
      height: inherit;
      margin: 0 auto 20px 0;
      width: 100%;
    }
    span {
      display: table;
      padding: 25px;
      color: #ffffff;
      font-size: 15px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.25px;
      width: 176px;
      height: 176px;
      margin: 0px auto;
      background-color: #ffa71d;
      border-radius: 150px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      z-index: 50;
      transition: all ease 0.4s;
      @media (max-width: 992px) {
        position: relative;
        top: initial;
        transform: initial;
        width: 100%;
        height: inherit;
        border-radius: 20px;
      }
      b {
        display: table-cell;
        vertical-align: middle;
        color: #ffffff;
        font-size: 15px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.25px;
        border-radius: 150px;
        transition: all ease 0.3s;
        .titre-pieuvre {
          font-weight: 300;
          font-family: "Roboto";
          transition: all ease 0.3s;
          opacity: 1;

        }
        .content-pieuvre {
          transition: all ease 0.3s;
          display: none;
          opacity: 0;
        }
        .txt-pieuvre {
          font-weight: 300;
          font-family: "Roboto";
          transition: all ease 0.3s;
          margin-top: 20px;
        }
      }
      @media (min-width: 992px) {
        &:hover {
          width: 286px;
          height: 286px;
          transition: all ease 0.4s;
        }
      }
    }
    &.partie0 {
      span {
        width: 286px;
        height: 286px;
        transition: all ease 0.4s;
        @media (max-width: 992px) {
          width: 100%;
          height: inherit;
        }
      }
      &:before {
        content: "";
        background-image: url(../../src/img/links.png);
        height: 477px;
        width: 483px;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 0;
        @media (max-width: 992px) {
          content: none;
        }
      }
    }
    &:hover {
      span {
        width: 286px;
        height: 286px;
        transition: all ease 0.3s;
      }
    }
  }
}

.section_8 {
  margin-bottom: 50px;
  h2 {
    color: #ffffff;
    font-family: "Montserrat Semi Bold";
    font-size: 40px;
    font-weight: 600;
    line-height: 55px;
    text-transform: uppercase;
    position: absolute;
  }
  .titre_timeline {
    position: absolute;
    z-index: 1;
    top: 45px;
    left: 0px;
    right: 0;
    width: 100%;
    margin: auto;
    display: block;
    text-align: center;
    color: #ffffff;
    font-family: "Montserrat Semi Bold";
    font-size: 40px;
    font-weight: 600;
    line-height: 55px;
    text-transform: uppercase;
  }
  .timeline {
    overflow: hidden;
    height: 470px;
    position: relative;
    .timeline-slide {
      height: 470px;
      padding: 0px;
      display: block;
      transition: all ease 0.4s;
      position: relative;

      @media (max-width: 768px) {
        padding: 0;
        padding: 0;
      }

      &:before {
        content: "";
        display: block;
        height: 4px;
        width: 100%;
        background-color: #fff;
        position: absolute;
        top: 49%;
        padding-left: 50px;
      }
      .annee {
        color: #ffffff;
        font-family: "Montserrat";
        font-size: 60px;
        font-weight: 600;
        line-height: 55px;
        text-transform: uppercase;
        position: absolute;
        top: 32%;
        left: 50px;
        &:before {
          content: "";
          display: block;
          width: 26px;
          height: 22px;
          background-color: #ffffff;
          border-radius: 40px;
          position: absolute;
          top: 71px;
          left: 50px;
          right: 0;
        }
      }
      .texte {
        color: #ffffff;
        font-family: "Montserrat";
        font-size: 17px;
        font-weight: 500;
        line-height: 25px;
        position: absolute;
        top: 56%;
        padding: 0 50px !important;
        @media (max-width: 992px) {
          max-width: 100%;
          width: 100%;
          padding: 0 15px;
        }
      }
      &.odd {
        background: #ff5570;
      }
      &.even {
        background: #ffa71d;
      }
      .button-slide {
        font-family: "Montserrat";
        font-size: 15px;
        font-weight: 600;
        display: block;
        background-color: #fff;
        width: fit-content;
        border-radius: 50px;
        padding: 0 20px;
        text-transform: initial;
        margin-top: 14px;
        opacity: 0;
        transition: all ease 0.4s;
      }
    }
    .slick-prev {
      text-indent: 9999;
      position: absolute;
      bottom: 20px;
      right: 100px;
      background: none;
      background-image: url(../../src/img/ar-l.png);
      background-repeat: no-repeat;
      height: 32px;
      width: 22px;
      border: none;
      z-index: 1;
      outline: none;
      cursor: pointer;
    }
    .slick-next {
      text-indent: 9999;
      position: absolute;
      bottom: 20px;
      right: 20px;
      background: none;
      background-image: url(../../src/img/ar-r.png);
      background-repeat: no-repeat;
      height: 32px;
      width: 22px;
      border: none;
      z-index: 1;
      outline: none;
      cursor: pointer;
    }
  }
}
