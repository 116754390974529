.item-team {
  display: block;
  position: relative;
  margin-bottom: 30px;
  transition: all ease 0.4s;
  &:hover {
    .block_image {
      background-color: #ffa71d;
      transition: all ease 0.4s;
    }
  }
  .block_image {
    height: 360px;
    width: 100%;
    border-radius: 20px;
    background-color: #ebebeb;
    position: relative;
    img {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
    }
  }
  h2 {
    color: #ff5570;
    text-transform: uppercase;
    font-family: "Montserrat";
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
  }
  .role-team {
    color: #4a5153;
    font-family: "Montserrat";
    font-weight: 600;
    line-height: 30px;
  }
  .site-team {
    font-family: "Montserrat";
    font-weight: 400;
    line-height: 30px;
  }
  .tele-team,
  .port-team {
    font-family: "Montserrat";
    font-weight: 400;
    line-height: 30px;
  }
}

.page-template-page-contact {
  .intro {
    padding-top: 30px;
    p {
      color: #4a5153;
      font-family: Montserrat;
      font-size: 17px;
      font-weight: 400;
      line-height: 30px;
    }
  }
  .section_1 {
    padding-top: 80px;
  }
}

.bloc-form {
  height: 925px;
  color: #fff;
  display: table;
  position: relative;
  padding: 80px;
  @media (max-width: 980px) {
    height: inherit;
    background: #ff5570;
    padding: 80px 25px;
  }
  h2 {
    margin: 15px 15px 35px 15px;
    color: #ffffff;
    font-family: "Montserrat";
    font-size: 40px;
    font-weight: 600;
    line-height: 55px;
    text-transform: uppercase;
  }
  input {
    width: 100%;
    background: none;
    border: none;
    border-bottom: 1px solid #fff;
    padding: 8px 0;
    color: #fff;
    font-weight: 400;
    outline: none;
    &:focus {
      outline: none;
    }
  }
  textarea {
    width: 100%;
    height: 130px;
    border: 1px solid #fff;
    background: none;
    margin-top: 15px;
    padding: 10px;
    outline: none;
    color: #fff;
    &:focus {
      outline: none;
    }
  }
  .wpcf7-submit {
    width: 80px;
    padding: 10px;
    border: 1px solid #fff;
    background-color: #fff;
    color: #ff5570;
    &:hover {
      border: 1px solid #fff;
      background-color: #ff5570;
      color: #fff;
      cursor: pointer;
    }
  }
  .elem {
    margin-bottom: 20px;
    float: left;
    color: #fff;
    font-family: Montserrat;
    font-size: 17px;
    font-weight: 400;
    line-height: 30px;
    padding-top: 20px;
  }
}

.bloc-info {
  height: 925px;
  display: table;
  position: relative;
  padding: 80px;
  @media (max-width: 980px) {
    height: inherit;
    padding: 80px 25px;
  }
  h3 {
    margin: 15px 15px 15px 0px;
    font-family: "Montserrat";
    font-weight: 600;
    line-height: 55px;
    text-transform: uppercase;
    color: #ff5570;
    font-size: 30px;
  }
  p {
    color: #4a5153;
    font-family: Montserrat;
    font-size: 17px;
    font-weight: 400;
    line-height: 30px;
  }
}

.vcenter {
  display: table-cell;
  vertical-align: middle;
}

.bg-infos {
  position: relative;
  margin-top: -160px;
  height: 925px;
  padding: 0;
  @media (max-width: 980px) {
    height: inherit;
  }
  &:before {
    content: "";
    background-color: #ff5570;
    width: 50%;
    height: 925px;
    display: block;
    position: absolute;
    @media (max-width: 980px) {
      height: inherit;
    }
  }

  @media (max-width: 980px) {
    &:before {
      content: "";
      width: 100%;
    }
  }
}

@media (min-width: 1200px) {
  .navbar-nav {
    .dropdown-menu {
      position: absolute;
      background: none;
      border: none;
      margin: 0;
      font-size: 13px;
      top: 38px;
      padding: 5px 0 0 0;
      background: #fff;
      min-width: 200px;
      li {
        a {
          color: #2dcaa0;
          padding: 8px 10px;
          width: 100%;
          transition: all ease 0.3s;
          &:hover {
            background-color: #2dcaa0;
            color: #fff;
            transition: all ease 0.3s;
          }
          &:before {
            content: none;
          }
          &:after {
            content: none;
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .navbar-nav {
    li {
      a {
        font-size: 20px!important;
        font-weight: bold;
      }
    }
    .open {
      .dropdown-menu {
        display: block;
        position: relative;
        top: inherit;
        background: none;
        min-width: inherit;
        border: none;
        li {
          display: grid;
          background-color: #fff;

          border-bottom: 1px solid rgba(0,0,0,0.15);
          a {
            font-size: 14px!important;
            color: #40dfb2;
          }
        }
      }
    }
  }
}

.nav .menu-item-has-children a::before,
.nav .menu-item-has-children a::after {
  content: none;
}

.map {
  padding: 0;
  #map-contact {
    height: 576px;
    width: 100%;
  }
}