//_nav.scss
.nav {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;

  .menu-item {
    margin-left: 30px;
    @media (max-width: 1360px) {
      margin-left: 13px;
    }
    a {
      font-weight: 600;
      color: $white;
      padding: 8px 0;
      display: inline-block;
      overflow: hidden;
      position: relative;
      &::before,
      &::after {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        height: 1px;
        background: $white;
        transition: transform 0.5s ease;
      }
      &::before {
        bottom: 0;
        transform: translateX(-100%);
      }
      &::after {
        top: 0;
        transform: translateX(100%);
      }
      &:hover::before,
      &:hover::after {
        transform: translateX(0);
      }
      @media (max-width: 992px) {
        padding: 6px 0;
      }
    }
  }
}