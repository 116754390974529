/*!
Theme Name: Adveris
Author: Adveris
Description: Starter Theme with Bootstrap to use with Timber
Version: 1.0.1
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: Adveris
Tags:
*/

/* BASE
--------------------------------------- */
@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "base/variables";
@import "base/reset";
@import "base/b-base";
@import "base/global";



/* MODULES - parties entières de site réutilisables
--------------------------------------- */
@import "modules/header";
@import "modules/m-nav";
@import "modules/footer";



/* COMPONENTS - petits éléments réutilisables dans le site ( boutons, logo de scroll, input, etc )
--------------------------------------- */
@import "components/c-link";
@import "components/c-language";
@import "components/c-button";
@import "components/_c-title.scss";
@import "components/_c-text.scss";


/* PAGES - css spécifique à chaque page
--------------------------------------- */
@import "pages/p-index";
@import "pages/p-actualites";
@import "pages/p-presentation";
@import "pages/p-secteur";
@import "pages/p-contact";
@import "pages/p-recrutement";