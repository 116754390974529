.list-actu {
  article {
    margin: 50px 0;
  }
}

.tool-pagination {
  margin: auto;
  display: inherit;
  a {
    width: 30px;
    height: 30px;
  }
  ul.pages {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      display: inline-block;
      color: #26292c;
      font-family: "Montserrat";
      font-size: 12px;
      width: 30px;
      height: 30px;
      transition: all ease 0.3s;
      text-align: center;
      span {
        display: inline-block;
        text-align: center;
        color: #26292c;
        font-family: "Montserrat";
        font-size: 24px;
        width: 30px;
        height: 30px;
        transition: all ease 0.3s;
        &.current {
          color: #ff5570;
          font-size: 24px;
          transition: all ease 0.3s;
        }
      }
      a {
        color: #26292c;
        text-align: center;
        font-family: "Montserrat";
        font-size: 12px;
        letter-spacing: initial;
        min-width: 30px;
        min-height: 30px;
        width: 30px;
        height: 30px;
        transition: all ease 0.3s;
        &:hover {
          color: #ff5570;
          transition: all ease 0.3s;
        }
      }
    }
  }
  .next, .prev {
    margin-top: 9px;
    color: #ff5570;
    text-align: center;
  }
}

.head-article {
  margin: 40px auto 60px auto;
  color: #ff5570;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 600;
  .cover-image {
    margin: -250px auto 40px auto;
    background-size: cover;
    width: 100%;
  }
  a {
    color: #ff5570;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 600;
  }
  .back {
    float: left;
    display: block;
  }
  .date {
    float: right;
  }
}

.body-article {
  margin-bottom: 40px;
}

.footer-article {
  margin: 40px auto;
  color: #ff5570;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 600;
  a {
    color: #ff5570;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 600;
  }
  .gauche {
    text-align: left;
    display: block;
  }
  .centrer {
    text-align: center;
    display: block;
    color: #fff;
    &:hover {
      color: #ff5570;
    }
  }
  .droite {
    text-align: right;
    display: block;
  }
}
