/*
Fonts*/
$font-family : 'Montserrat', sans-serif;
$font-custom : 'Oswald', sans-serif;

/*
Colors*/
$pink: #FF5570;
$green: #40e0b4;
$white: #FFFFFF;
$black: #000000;
$grey: #fbfcfd;
$text: #4a5153;