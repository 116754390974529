//_c-title.scss

.title{
  font-size: 30px;
  line-height: 50px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  margin: 50px 0 80px;
  font-family: "Montserrat Semi Bold";
  &--pink{
    color: $pink;
  }
  &--green{
    color: $green;
  }
}