//_p-index.scss
.citation {
  font-size: 30px;
  font-weight: 600;
  line-height: 60px;
  color: $white;
  margin-top: 70px;
  margin-bottom: 200px;
  width: 100%;
  @media (max-width: 992px) {
    width: 100%;
    display: block;
    text-align: center;
    font-size: 28px;
  }
  .phrase {
    transition: all 0.5s ease;
    width: 100%;
    text-align: center;
    display: block;
    @media (max-width: 992px) {
      width: 100%;
      display: block;
    }
  }
  .author {
    font-size: 22px;
    display: block;
    font-weight: 400;
    text-align: right;
    @media (max-width: 992px) {
      width: 100%;
      display: block;
      text-align: center;
      font-size: 24px;
    }
  }
}

.presentation {
  .title {
    font-size: 25px;
  }
  p {
    color: #4a5153;
    font-family: Montserrat;
    font-size: 17px;
    font-weight: 400;
    line-height: 31px;
  }
}

.legends {
  .number {
    padding: 0px;
    min-height: 90px;
    font-family: "Montserrat";
    font-weight: 400;
    color: #4a5153;
    @media (max-width: 992px) {
      padding: 0 20px 10px 20px;
      min-height: 0px;
    }
  }
}

.number {
  display: inline-block !important;
  &-count {
    color: $pink;
    font-size: 60px;
    font-weight: 600;
    line-height: 30px;
    line-height: 30px;
    line-height: 30px;
    margin-bottom: 0;
    span {
      font-family: "Montserrat Semi Bold";
      font-size: 60px;
      font-weight: 600;
    }
  }
  .counterUp {
    color: #ff5570;
    font-family: "Montserrat Semi Bold";
    font-size: 60px;
    font-weight: 600;
    line-height: 41px;
    text-transform: uppercase;
    @media (max-width: 1400px) {
      font-size: 50px;
    }
    @media (max-width: 1200px) {
      font-size: 35px;
    }
  }
  .unite {
    color: #ff5570;
    font-size: 24px;
    line-height: initial;
    text-transform: uppercase;
    @media (mfax-width: 1400px) {
      font-size: 20px;
    }
    @media (max-width: 1200px) {
      font-size: 18px;
    }
    @media (max-width: 992px) {
      font-size: 16px;
    }
  }
}

.others {
  padding-bottom: 60px;
  .picto-container {
    margin-bottom: 100px;
  }
  .legende{
    color: #4a5153;
    font-family: Montserrat;
    font-size: 25px;
    font-weight: 400;
    line-height: 25px;
    @media (max-width: 1200px) {
      font-size: 18px;
    }
  }
}

.bloc-actu {
  padding-bottom: 160px;
  .actu {
    position: relative;
    margin-top: -130px;
    margin-bottom: -160px;
    z-index: 1;
    &-item {
      border-radius: 15px;
      background-color: $white;
      box-shadow: 0 0 5px #f6f6f6;
      transition: all 0.5s ease;
      line-height: 30px;
      &:hover {
        box-shadow: 0 1px 4px darken(#f6f6f6, 20%);
      }
    }
    &-img {
      height: 200px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: top;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
    &-content {
      padding: 20px 30px 40px;
    }
    &-date,
    &-title {
      color: $pink;
      font-weight: 500;
      margin: 0;
    }
    &-date {
      font-size: 12px;
      letter-spacing: 1.5px;
    }
    &-title {
      font-size: 20px;
    }
    &-text {
      margin-bottom: 25px;
    }
    .actu-img {
      background-size: cover;
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
    }
    article {
      margin: 30px auto 0 auto;
    }
  }
}

.more {
  .title {
    margin: 30px 0px 60px 0;
  }
  .hover-effect {
    a {
      padding: 10px;
      position: relative;
      display: inline-block;
      overflow: hidden;
      &::before,
      &::after {
        content: "";
        position: absolute;
        width: 2px;
        height: 100%;
        background-color: $green;
        transition: all 0.3s ease;
      }
      &::before {
        top: 0;
        left: 0;
        transform: translateY(-100%);
      }
      &::after {
        bottom: 0;
        right: 0;
        transform: translateY(100%);
      }
      span {
        padding: 10px;
        &::before,
        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          background-color: $green;
          transition: all 0.3s ease;
        }
        &::before {
          top: 0;
          left: 0;
          transform: translateX(100%);
        }
        &::after {
          bottom: 0;
          left: 0;
          transform: translateX(-100%);
        }
      }
      &:hover::before,
      &:hover::after,
      span:hover::before,
      span:hover::after {
        transform: translateX(0);
      }
    }
  }
}

.clients {
  padding: 50px 0;
  .title {
    margin: 0;
    text-align: left;
    font-size: 26px;
    @media (max-width: 992px) {
      text-align: center;
    }
  }
  .swiper-partners,
  .swiper-partners2 {
    overflow: hidden;
    margin: 40px 20px 0 20px;
    img {
      //filter: grayscale(100%);
      transition: all 0.3s ease;
      margin: auto;
      height: 45px;
      width: initial;
      &:hover {
        //filter: grayscale(0);
      }
    }
  }
}

.chiffre-block {
  padding-top: 100px;
  margin-top: -50px;
  margin-bottom: -50px;
}

.swiper-section {
  padding: 0 0 0px 0;
  margin-top: -60px;
  @media (max-width: 768px) {
    margin-top: 50px;
  }
  .slider-home {
    .title {
      margin: 0;
    }
    .swiper-home {
      overflow: hidden;
      height: 630px;
      @media (max-width: 768px) {
        height: 400px;
      }
      .swiper-slide {
        cursor: default;
        background-size: cover;
        background-position: center;
        display: table;
        .titre {
          display: table-cell;
          text-align: left;
          color: #ffffff;
          font-family: "Montserrat";
          font-size: 40px;
          font-weight: 600;
          line-height: 50px;
          text-transform: uppercase;
          vertical-align: middle;
          height: 630px;
          position: relative;
          z-index: 5;
          padding: 0 100px;
          transition: all ease 0.4s;
          @media (max-width: 1400px) {
            padding: 0 60px;
            font-size: 36px;
            line-height: 40px;
          }
          @media (max-width: 992px) {
            padding: 0 15px;
            font-size: 26px;
            line-height: 30px;
          }
          @media (max-width: 768px) {
            padding: 0 15px;
            font-size: 24px;
            line-height: 28px;
            height: 400px;
          }
        }
        .button-slide {
          font-family: "Montserrat";
          font-size: 15px;
          font-weight: 600;
          z-index: 5;
          display: block;
          background-color: #fff;
          width: fit-content;
          border-radius: 50px;
          padding: 0 20px;
          text-transform: initial;
          margin-top: 14px;
          opacity: 0;
          transition: all ease 0.4s;
        }
        .titre-article {
          color: #fff;
        }
      }
    }
  }
  .swiper-secteur-home {
    &.odd {
      &:after {
        content: "";
        background-color: #40E0B4;
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        z-index: 10;
        transition: all ease 0.4s;
        @media (max-width: 768px) {
          display: none;
        }
      }
      &:hover {
        &:after {
          content: "";
          display: none;
          transition: all ease 0.4s;
        }
        .titre {
          text-align: left;
          transition: all ease 0.4s;
          .button-slide {
            opacity: 1;
            transition: all ease 0.4s;
          }
        }
      }
      &:before {
        content: "VOTRE SECTEUR D'ACTIVITÉ";
        padding: 0 20%;
        width: 100%;
        top: 43%;
        text-align: center;
        display: block;
        position: absolute;
        z-index: 11;
        transition: all ease 0.4s;
        color: #ffffff;
        font-family: "Montserrat";
        font-size: 40px;
        font-weight: 600;
        line-height: 55px;
        text-transform: uppercase;
        @media (max-width: 768px) {
          display: none;
        }
      }
      .button-slide {
        color: #40e0b4;
      }
      &.txten{
        &:before {
          content: "YOUR SECTOR";
        }
      }
    }
    .swiper-slide {
      &:before {
        content: "";
        background-color: #40E0B4;
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        z-index: 4;
        transition: all ease 0.4s;
        opacity: 0.3;
      }
    }
    .commandes {
      color: #40E0B4;
    }
    &:hover {
      &:before {
        content: ""!important;
      }
    }
  }
  .swiper-metier-home {
    &.even {
      &:after {
        content: "";
        background-color: #ff5570;
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        z-index: 10;
        transition: all ease 0.4s;
        @media (max-width: 768px) {
          display: none;
        }
      }
      &:hover {
        &:after {
          content: "";
          display: none;
          transition: all ease 0.4s;
        }
        .titre {
          text-align: left;
          transition: all ease 0.4s;
          .button-slide {
            opacity: 1;
            transition: all ease 0.4s;
          }
        }
      }
      &:before {
        content: "VOTRE METIER";
        padding: 0 10%;
        width: 100%;
        top: 43%;
        text-align: center;
        display: block;
        position: absolute;
        z-index: 11;
        transition: all ease 0.4s;
        color: #ffffff;
        font-family: "Montserrat";
        font-size: 40px;
        font-weight: 600;
        line-height: 55px;
        text-transform: uppercase;
        @media (max-width: 768px) {
          display: none;
        }
      }
      .button-slide {
        color: #ff5570;
      }
      &.txten{
        &:before {
          content: "YOUR FUNCTION";
        }
      }
    }
    .swiper-slide {
      &:before {
        content: "";
        background-color: #ff5570;
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        z-index: 4;
        transition: all ease 0.4s;
        opacity: 0.3;
      }
    }
    .commandes {
      color: #ff5570;
    }
    &:hover {
      &:before {
        content: ""!important;
      }
    }
  }
}

.commandes {
  background-color: #fff;
  height: 60px;
  width: 320px;
  position: absolute;
  bottom: 0;
  right: 0px;
  z-index: 2;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  .swiper-button-prev {
    position: absolute;
    left: 20px;
    top: 40px;
    z-index: 9;
  }
  .swiper-button-next {
    position: absolute;
    left: 70px;
    top: 40px;
    z-index: 9;
  }
  .swiper-pagination1,
  .swiper-pagination2{
    width: 70px;
    height: 23px;
    position: absolute;
    right: 0;
    left: inherit;
    top: 17px;
    font-family: "Montserrat";
    font-size: 15px;
    font-weight: 500;
    line-height: 25px;
  }
  .text-commande {
    position: absolute;
    top: 17px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    font-family: "Montserrat";
    font-size: 15px;
    font-weight: 500;
    line-height: 25px;
  }
}

.right-slider {
  display: block;
  background-color: #ff5570;
  height: 630px;
  span {
    color: #ffffff;
    font-family: "Montserrat";
    font-size: 40px;
    font-weight: 600;
    line-height: 55px;
    text-transform: uppercase;
    text-align: center;
  }
}

.swiper-slide{
  cursor: default;
  img{
    cursor: default;
  }
}