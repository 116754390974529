.page-template-page-secteur,
.page-template-page-metier,
.page-template-page-contact {
  .head-secteur {
    h1 {
      color: #ff5570;
      font-family: "Montserrat";
      font-size: 30px;
      font-weight: 600;
      line-height: 50px;
      text-transform: uppercase;
      text-align: center;
      span {
        text-transform: lowercase;
      }
    }
    p {
      color: #4a5153;
      font-family: Montserrat;
      font-size: 17px;
      font-weight: 400;
      line-height: 30px;
    }
  }
  .swiper-partners2 img {
    display: block;
    margin: auto;
    opacity: 0.4;
  }
  #grande-image {
    min-height: inherit;
    height: inherit;
    background-position: center;
    background-size: cover;
    padding: 100px 0 0px 0;
    img {
      margin: auto;
    }
  }
  h2 {
    color: #ff5570;
    font-family: "Montserrat";
    margin-bottom: 40px;
    font-size: 30px;
    font-weight: 600;
    line-height: 50px;
    text-transform: uppercase;
    text-align: center;
  }
  .list {
    li {
      padding-bottom: 20px;
    }
  }
  .enjeux {
    box-shadow: 0 0 20px #d4d4d4;
    padding: 50px 15px 50px 0;
    margin-top: -65px;
    background-color: #fff;
    @media (max-width: 768px) {
      margin-top: 0px;
    }
  }
  .expertises {
    background-color: #ffa71d;
    color: #ffffff;
    font-family: "Montserrat";
    font-size: 17px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
    h2 {
      color: #fbfcfd;
      font-family: "Montserrat";
      font-size: 30px;
      font-weight: 600;
      line-height: 50px;
      text-transform: uppercase;
      text-align: center;
    }
    p {
      color: #ffffff;
      font-family: "Montserrat";
      font-size: 17px;
      font-weight: 500;
      line-height: 30px;
      text-align: center;
    }
  }
  .slider-secteur {
    background-color: #fbfcfd;
  }
  .item-contact-team,
  .item-team {
    display: block;
    position: relative;
    margin-bottom: 30px;
    transition: all ease 0.4s;
    @media (max-width: 768px) {
      text-align: center;
    }
    &:hover {
      transition: all ease 0.4s;
    }
    .block_image {
      width: 100%;
      border-radius: 20px;
      position: relative;
      img {
        @media (max-width: 768px) {
          margin: auto;
        }
      }
    }
    h2 {
      color: #ff5570;
      text-transform: uppercase;
      font-family: "Montserrat";
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      text-align: center;
      margin: 0;
    }
    p {
      text-align: center;
    }
    .role-team {
      color: #4a5153;
      font-family: "Montserrat";
      font-weight: 600;
      line-height: 30px;
      text-align: center;
    }
    .site-team {
      font-family: "Montserrat";
      font-weight: 400;
      line-height: 30px;
      text-align: center;
      a{
        color: #4a5153;
      }
    }
    .tele-team,
    .port-team {
      font-family: "Montserrat";
      font-weight: 400;
      line-height: 30px;
      text-align: center;
    }
  }
  .etudes {
    margin-top: 40px;
    .item {
      background-color: #fff;
      padding: 20px 5px;
      height: 310px;
      width: 100%;
      display: table;
      border-radius: 20px;
      margin-bottom: 30px;
      .item-cell {
        display: table-cell;
        vertical-align: middle;
        height: 310px;
        h3 {
          color: #ff5570;
          font-size: 17px;
          font-family: "Montserrat";
          font-weight: 600;
          text-transform: uppercase;
        }
        .texte {
          color: #4a5153;
          font-size: 14px;
          line-height: 23px;
          font-family: Montserrat;
          font-weight: 400;
        }
      }
    }
  }
  .secteurs {
    text-align: center;
    img {
      display: block;
      margin: auto;
      margin-top: 40px;
      height: 41px;
    }
    h3 {
      color: #4a5153;
      font-family: Montserrat;
      font-size: 22px;
      font-weight: 400;
      line-height: 30px;
      margin-top: 40px;
      @media (max-width: 1200px) {
        font-size: 20px;
      }
      @media (max-width: 992px) {
        font-size: 16px;
      }
      a {
        color: #4a5153;
      }
    }
  }
}

.bloc-logo {
  padding: 100px 0;
}

.bloc-logo,
.swiper-section,
.expertises {
  .swiper-button-next, .swiper-button-prev,
  .swiper-button-next-expert, .swiper-button-prev-expert {
    width: 12px;
    height: 20px;
    margin-top: -20px;
    background-size: inherit;
    outline: none;
  }
  .swiper-button-prev,
  .swiper-button-prev-expert {
    background-image: url("../../src/img/arrow-point-to-left.png") !important;
  }
  .swiper-button-next,
  .swiper-button-next-expert {
    background-image: url("../../src/img/arrow-point-to-right.png") !important;
  }

  .btp3 {
    background-image: url("../../src/img/arrow-point-to-left3.png") !important;
    margin-left: -30px;
  }
  .btn3 {
    background-image: url("../../src/img/arrow-point-to-right3.png") !important;
    margin-right: -30px;
  }
}

.bloc-logo {
  .swiper-button-next, .swiper-button-prev {
    margin-top: -11px;
  }
}

.expertises {
  .swiper-experts {
    overflow: hidden;
  }

  @media (min-width: 580px) {
    .swiper-button-prev {
      left: -15px;
      z-index: 1111;
      position: absolute;
    }
    .swiper-button-next {
      right: -15px;
      z-index: 1111;
      position: absolute;
    }
  }
}

.scrollToTop {
  width: 40px;
  height: 40px;
  background-color: #FF5570;
  border-radius: 100%;
  position: fixed;
  font-size: 24px;
  color: #fff;
  border: none;
  right: 30px;
  bottom: 30px;
  display: none;
  outline: 0;
  cursor: pointer;
  z-index: 1;
}